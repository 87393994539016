import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getScheduleRulesFromAvailabilityPeriods } from '~/modules/common/hooks/resourcing';
import {
  useUpdateTaskResourceUserAllocation,
  useCreateTaskResourceUserAllocation
} from '~/modules/common/components/TaskDrawer/common/hooks';
import {
  getAvailabilityPeriodsFromDates,
  omitForbiddenFields
} from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { mapRepliconDateToMidnightUTCString } from '~/modules/common/dates/convert';

export const getTaskAllocationScheduleRules = ({
  resourceAllocationScheduleRules = [],
  otherTaskAllocationsSummaryScheduleRules = [],
  values
}) => {
  const { startDate, endDate, projectAvailableHours, allocatedHours } = values;

  const {
    availabilityPeriods,
    totalAvailableHours
  } = getAvailabilityPeriodsFromDates({
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules,
    startDate,
    endDate
  });

  // Main.min handles edge case scenario where projectAvailableHours < allocatedHours due to rounding differences
  // Example Scenario: Available = 199.995 rounded to 200.00, and user allocation is 200 yet user availability is only 199.995
  const availabilityFactor =
    Math.min(allocatedHours, projectAvailableHours) / totalAvailableHours;

  return getScheduleRulesFromAvailabilityPeriods({
    availabilityPeriods,
    availabilityFactor
  });
};

export const getTaskAllocationFieldsFromCacheData = mutationType => ({
  cacheData,
  isRmpTaskAllocationPhase2Enabled,
  fields
}) => {
  if (!isRmpTaskAllocationPhase2Enabled) {
    const { id, startDate, endDate, scheduleRules, totalHours } = fields;

    return {
      id,
      startDate,
      endDate,
      totalHours,
      scheduleRules
    };
  }

  const {
    data: {
      [mutationType]: { taskResourceUserAllocation }
    }
  } = cacheData;

  const {
    id,
    startDate,
    endDate,
    scheduleRules,
    totalHours
  } = taskResourceUserAllocation;

  return {
    id,
    startDate,
    endDate,
    scheduleRules,
    totalHours
  };
};
export const onUpdate = ({
  setFieldValue,
  resourceEstimates,
  resourceEstimateId,
  taskAllocationId,
  projectId,
  roleId,
  scheduleRules,
  taskId,
  getTaskAllocationFields,
  hours,
  startDate,
  endDate,
  isRmpTaskAllocationPhase2Enabled
}) => (_, data) => {
  setFieldValue(
    'resourceEstimates',
    resourceEstimates.map(estimate =>
      estimate.id === resourceEstimateId
        ? {
            ...estimate,
            taskResourceUserAllocation: {
              ...getTaskAllocationFields({
                cacheData: data,
                isRmpTaskAllocationPhase2Enabled,
                fields: {
                  id: taskAllocationId,
                  startDate: mapRepliconDateToMidnightUTCString(startDate),
                  endDate: mapRepliconDateToMidnightUTCString(endDate),
                  scheduleRules,
                  totalHours: hours
                }
              }),
              projectUri: projectId,
              roleUri: roleId,
              taskUri: taskId
            }
          }
        : estimate
    )
  );
};

export const useOnSubmit = ({
  setFieldValue,
  resourceEstimates,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();
  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();

  return useCallback(
    async ({
      setSubmitting,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      projectId,
      taskId,
      userId,
      tenantSlug,
      roleId,
      values,
      resourceEstimateId,
      taskResourceUserAllocation
    }) => {
      setSubmitting(true);

      const {
        startDate,
        endDate,
        allocatedHours,
        projectAvailableHours
      } = values;

      const taskAllocationId =
        taskResourceUserAllocation?.id ||
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      const scheduleRules = getTaskAllocationScheduleRules({
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules:
          otherTaskAllocationsSummaryScheduleRules || [],
        values
      });

      const additionalParams = isRmpTaskAllocationPhase2Enabled
        ? {
            dateRange: {
              startDate,
              endDate
            },
            allocationHours: allocatedHours,
            scheduleRules: undefined
          }
        : {};

      const onUpdateParams = {
        setFieldValue,
        resourceEstimates,
        resourceEstimateId,
        taskAllocationId,
        projectId,
        roleId,
        scheduleRules,
        taskId,
        hours: Math.min(projectAvailableHours, allocatedHours),
        startDate,
        endDate
      };

      await (taskResourceUserAllocation
        ? updateTaskResourceUserAllocation({
            variables: {
              input: {
                ...omitForbiddenFields({
                  taskAllocationId,
                  taskUri: taskId,
                  projectUri: projectId,
                  roleUri: roleId,
                  scheduleRules
                }),
                ...additionalParams
              }
            },
            update: onUpdate({
              ...onUpdateParams,
              isRmpTaskAllocationPhase2Enabled,
              getTaskAllocationFields: getTaskAllocationFieldsFromCacheData(
                'updateTaskResourceUserAllocation'
              )
            })
          })
        : createTaskResourceUserAllocation({
            variables: {
              input: {
                ...omitForbiddenFields({
                  taskAllocationId,
                  taskUri: taskId,
                  projectUri: projectId,
                  allocationUserUri: userId,
                  roleUri: roleId,
                  scheduleRules
                }),
                ...additionalParams
              }
            },
            update: onUpdate({
              ...onUpdateParams,
              isRmpTaskAllocationPhase2Enabled,
              getTaskAllocationFields: getTaskAllocationFieldsFromCacheData(
                'createTaskResourceUserAllocation'
              )
            })
          }));

      setSubmitting(false);
    },
    [
      createTaskResourceUserAllocation,
      isRmpTaskAllocationPhase2Enabled,
      resourceEstimates,
      setFieldValue,
      updateTaskResourceUserAllocation
    ]
  );
};

export default useOnSubmit;
