import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import usePageOfResourceUsersDropdownOptions from './hooks/usePageOfResourceUsersDropdownOptions';
import usePageOfProjectResourceDropdownOptions from './hooks/usePageOfProjectResourceDropdownOptions';

const PAGE_SIZE = 200;

const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const tabs = {
  PROJECT_RESOURCES: 'PROJECT_RESOURCES',
  ALL_RESOURCES: 'ALL_RESOURCES'
};

const NO_OPTION_ID = 'resourceUsersDropdown.noOption';
const TAB_ID = 'resourceUsersDropdown.tabId';

const getHasAssignedRole = ({ userRoles, assignedRole }) =>
  Number(Boolean(userRoles?.find(r => r.projectRole?.uri === assignedRole.id)));

export const addMoreAvailableOptionItem = ({
  options,
  hasMoreRows,
  formatMessage,
  loading
}) => {
  return [
    ...options,
    ...(!loading && hasMoreRows
      ? [
          {
            id: MORE_AVAILABLE_OPTION_ID,
            displayText: formatMessage(
              {
                id: MORE_AVAILABLE_OPTION_ID
              },
              {
                option: formatMessage({
                  id: 'moreOptions.users'
                })
              }
            ),
            group: '',
            filter: false
          }
        ]
      : [])
  ];
};

export const sortByGroup = ({
  aValue,
  bValue,
  aGroup,
  bGroup,
  order = SORT_ORDER.ASC
}) => {
  if (aGroup !== bGroup) {
    return 0;
  }

  if (aValue === bValue) {
    return 0;
  }

  return order === SORT_ORDER.DESC
    ? aValue < bValue
      ? 1
      : -1
    : aValue > bValue
    ? 1
    : -1;
};

export const sortByField = (field, order = SORT_ORDER.ASC) => (a, b) => {
  const aGroup = a?.group || '';
  const bGroup = b?.group || '';

  const aValue =
    field === 'displayText'
      ? (a?.displayText || '').toUpperCase()
      : a?.availableDuration || 0;

  const bValue =
    field === 'displayText'
      ? (b?.displayText || '').toUpperCase()
      : b?.availableDuration || 0;

  return sortByGroup({
    aValue,
    bValue,
    aGroup,
    bGroup,
    order
  });
};

export const getUsersOptions = (users, assignedRole, formatMessage, sort) => {
  return users
    .map(u => {
      const group = u.hasAssignedRole
        ? `${assignedRole.displayText} ${formatMessage({
            id: 'addResource.resources'
          })}`
        : u.id === MORE_AVAILABLE_OPTION_ID
        ? null
        : u.isAllocatedResource
        ? formatMessage({ id: 'taskResourceAssignments.allocatedResources' })
        : formatMessage({ id: 'taskResourceAssignments.timesheetAccess' });

      return { ...u, group };
    })
    .sort(sortByField(sort?.field, sort?.direction));
};

export const useDropdownOptions = ({
  projectId,
  assignedRole,
  onResourceChange,
  selectedResources,
  taskId,
  isProjectRoleEnabled,
  startDate,
  endDate,
  isAvailabilityColumnEnabled,
  sort,
  open
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabs.PROJECT_RESOURCES);

  const availabilityPercentageInRange = isAvailabilityColumnEnabled
    ? {
        dateRange: {
          startDate,
          endDate
        },
        number: 0
      }
    : null;

  const {
    loading: loadingProjectResources,
    users,
    hasMore: hasMoreProjectResources
  } = usePageOfProjectResourceDropdownOptions({
    isAvailabilityEnabled: isAvailabilityColumnEnabled,
    projectId,
    searchTerm,
    pageSize: PAGE_SIZE,
    availabilityPercentageInRange,
    skip: !open || selectedTab === tabs.ALL_RESOURCES,
    assignedTaskId: taskId
  });

  const {
    isLoading,
    resourceUsers,
    hasMoreRows: hasMoreResourceUsers,
    showAllUsers
  } = usePageOfResourceUsersDropdownOptions({
    isAvailabilityEnabled: isAvailabilityColumnEnabled,
    userRole: assignedRole,
    isProjectRoleEnabled,
    userSearchText: searchTerm,
    availabilityPercentageInRange,
    skip: !open || selectedTab === tabs.PROJECT_RESOURCES,
    sort,
    assignedTaskId: taskId
  });

  const handleOnChange = useCallback(
    resources => {
      const resource = resources?.length
        ? resources[resources.length - 1]
        : null;

      if (resource?.id === TAB_ID) {
        return;
      }
      onResourceChange(resource);
      setSearchTerm('');
    },
    [onResourceChange, setSearchTerm]
  );

  const handleTabChange = useCallback((e, newValue) => {
    setSelectedTab(newValue);
    e.stopPropagation();
  }, []);

  const handleInputChange = (_, v) => setSearchTerm(v.trim());
  const loading = loadingProjectResources || isLoading;

  const getOptionDisabled = useCallback(
    option =>
      option.isAssignedToTask ||
      option.id === MORE_AVAILABLE_OPTION_ID ||
      option.id === NO_OPTION_ID,
    []
  );

  const getFormattedMessage = useCallback(() => {
    if (
      selectedTab === tabs.ALL_RESOURCES &&
      resourceUsers.length === 0 &&
      searchTerm === ''
    ) {
      return 'resourceUsersDropdown.noResources';
    }

    if (
      selectedTab === tabs.PROJECT_RESOURCES &&
      users.length === 0 &&
      searchTerm === ''
    ) {
      return 'resourceUsersDropdown.noProjectResources';
    }

    return 'resourceUsersDropdown.noMatchedResources';
  }, [selectedTab, resourceUsers, searchTerm, users]);

  const getOptions = useCallback(
    projectResources => {
      const options = assignedRole
        ? projectResources
            .map(pr => ({
              ...pr,
              hasAssignedRole: getHasAssignedRole({
                userRoles: pr.roles,
                assignedRole
              })
            }))
            .sort((a, b) => b.hasAssignedRole - a.hasAssignedRole)
        : projectResources;

      return options;
    },
    [assignedRole]
  );

  return {
    handleTabChange,
    handleInputChange,
    users: addMoreAvailableOptionItem({
      options: getUsersOptions(
        getOptions(users),
        assignedRole,
        formatMessage,
        sort
      ),
      hasMoreRows: hasMoreProjectResources,
      formatMessage,
      loading
    }),
    hasMore: hasMoreProjectResources || hasMoreResourceUsers,
    loading,
    resourceUsers: addMoreAvailableOptionItem({
      options: resourceUsers.map(ru => {
        const group =
          !assignedRole || !isProjectRoleEnabled
            ? formatMessage({ id: 'addResource.resource' })
            : ru.isRoleExists
            ? `${assignedRole.displayText} ${formatMessage({
                id: 'addResource.resources'
              })}`
            : showAllUsers &&
              formatMessage({ id: 'addResource.otherResources' });

        return { ...ru, group };
      }),
      hasMoreRows: hasMoreResourceUsers,
      formatMessage,
      loading
    }),
    selectedTab,
    handleOnChange,
    getFormattedMessage,
    getOptionDisabled,
    searchTerm,
    getOptions
  };
};
