import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { specificTaskResourceUserAllocationFragment } from './specificTaskResourceUserAllocationFragment';

export const CREATE_TASK_RESOURCE_USER_ALLOCATION = gql`
  mutation CreateTaskResourceUserAllocation(
    $input: CreateTaskResourceUserAllocationInput!
  ) {
    createTaskResourceUserAllocation(input: $input) {
      taskResourceUserAllocation {
        ...SpecificTaskResourceUserAllocation
      }
      syncedResourceAllocation {
        id
        totalHours
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
    }
  }
  ${specificTaskResourceUserAllocationFragment}
`;

export const useCreateTaskResourceUserAllocation = () => {
  const [createTaskResourceUserAllocationMutation] = useMutation(
    CREATE_TASK_RESOURCE_USER_ALLOCATION
  );

  return createTaskResourceUserAllocationMutation;
};

export default useCreateTaskResourceUserAllocation;
